import React, { useState, useEffect, useRef, useCallback } from 'react';
import Pica from 'pica';
import { useDropzone } from 'react-dropzone';
import Draggable from 'react-draggable'; // Import Draggable
import '../styles/App.css';
import ImageDisplay from '../components/app/ImageDisplay';
import CropFrame from '../components/app/CropFrame';
import CropFrameDialog from '../components/app/CropFrameDialog';

function Home({ onFileDrop }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [cropFrames, setCropFrames] = useState([]);
  const [croppedImages, setCroppedImages] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedCropIndex, setSelectedCropIndex] = useState(-1);
  const [zoomFactor, setZoomFactor] = useState(1);
  const [addCropButtonVisible, setAddCropButtonVisible] = useState(true);
  const [isDraggingOverWindow, setIsDraggingOverWindow] = useState(false);
  const [isZoomInHovered, setIsZoomInHovered] = useState(false);
  const [isZoomOutHovered, setIsZoomOutHovered] = useState(false);
  const [showDownloads, setShowDownloads] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [cropLayerNames, setCropLayerNames] = useState([]);
  const [isDraggedImage, setIsDraggedImage] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const zoomContainerRef = useRef(null);
  const imageRef = useRef(null);

// Function to clear the image
const clearImage = () => {
  setSelectedImage(null);
  setSelectedImageFile(null);
  setImageDimensions({ width: 0, height: 0 });
  setCropFrames([]);
  setCroppedImages([]);
  setSelectedImages([]);
  setZoomFactor(1); // Optionally reset zoom to 1
  // You might also want to reset any other states or references as needed
};
  // Function to toggle selection of an image
  const toggleImageSelection = (index) => {
    if (selectedImages.includes(index)) {
      setSelectedImages((prevSelected) => prevSelected.filter((item) => item !== index));
    } else {
      setSelectedImages((prevSelected) => [...prevSelected, index]);
    }
  };

  // Function to check if an image is selected
  const isImageSelected = (index) => {
    return selectedImages.includes(index);
  };

  // Function to download selected images
  const downloadSelectedImages = () => {
    selectedImages.forEach((index) => {
      const croppedImageData = croppedImages[index];
      downloadCroppedImage(croppedImageData.croppedImage, croppedImageData.filename, 'png');
    });
  };

  // Function to handle dragging over window
  const handleDragOverWindow = (e) => {
    e.preventDefault(); // Prevent default behavior for dragover
    setSelectedImage(null); // Reset selectedImage when dragging over the window
    setIsDraggingOverWindow(true);
  };

  const handleDragLeaveWindow = () => {
    setIsDraggingOverWindow(false);
  };

  useEffect(() => {
    // Add event listeners when the component mounts
    document.addEventListener('dragover', handleDragOverWindow);
    document.addEventListener('dragleave', handleDragLeaveWindow);

    // Remove event listeners when the component unmounts
    return () => {
      document.removeEventListener('dragover', handleDragOverWindow);
      document.removeEventListener('dragleave', handleDragLeaveWindow);
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const selectCropFrame = (index) => {
    setSelectedCropIndex(index);
  };
  
  // Memoize the centerImageInWindow function
  const centerImageInWindow = useCallback((imageWidth, imageHeight) => {
    if (!zoomContainerRef.current) return;

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Determine the size of the zoom container
    const containerWidth = Math.max(imageWidth * zoomFactor, viewportWidth);
    const containerHeight = Math.max(imageHeight * zoomFactor, viewportHeight);

    // Update the zoom container size
    zoomContainerRef.current.style.width = `${containerWidth}px`;
    zoomContainerRef.current.style.height = `${containerHeight}px`;

    // Calculate the scroll offset to center the image
    const scrollLeft = Math.max(0, (containerWidth - viewportWidth) / 2);
    const scrollTop = Math.max(0, (containerHeight - viewportHeight) / 2);

    // Debugging output
    console.log('Container Width:', containerWidth, 'Container Height:', containerHeight);
    console.log('Scroll Left:', scrollLeft, 'Scroll Top:', scrollTop);

    // Ensure scrolling only if needed
    if (scrollLeft > 0 || scrollTop > 0) {
      window.scrollTo({
        top: scrollTop,
        left: scrollLeft,
        behavior: 'auto',
      });
    }
  }, [zoomFactor]);

  // useEffect to center image in window
  useEffect(() => {
    if (selectedImage && imageDimensions.width && imageDimensions.height) {
      // Use setTimeout to ensure layout is updated
      setTimeout(() => {
        centerImageInWindow(imageDimensions.width, imageDimensions.height);
      }, 100); // Delay to ensure the image is rendered
    }
  }, [selectedImage, imageDimensions.width, imageDimensions.height, centerImageInWindow]);

  // useEffect to handle zoom changes
  useEffect(() => {
    if (selectedImage && imageDimensions.width && imageDimensions.height) {
      centerImageInWindow(imageDimensions.width, imageDimensions.height);
    }
  }, [zoomFactor, selectedImage, imageDimensions.width, imageDimensions.height, centerImageInWindow]);


  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
        setSelectedImage(reader.result);

        // Center the image in the window after it's fully loaded
        centerImageInWindow(img.width, img.height);
      };
    };

    reader.readAsDataURL(file);

    // Other state updates
    setSelectedImageFile(file);
    setIsDraggedImage(true);
    setAddCropButtonVisible(true);

    onFileDrop(acceptedFiles);
  };

  const openDialog = () => {
    setDialogVisible(true);
    setAddCropButtonVisible(false);
  };

  const closeDialog = () => {
    setDialogVisible(false);
    setAddCropButtonVisible(true);
  };

  const handleDialogConfirm = (width, height) => {
    addCropFrame(10, 10, width, height);
    closeDialog();
  };

  const toggleDownloads = () => {
    setShowDownloads((prev) => !prev);
  };

  const addCropFrame = (x, y, width, height) => {
    const newFrame = { x, y, width, height, originalWidth: width, originalHeight: height };
    const newName = `Crop Frame ${cropLayerNames.length + 1}`; // Example name
    setCropFrames((prevFrames) => {
      const updatedFrames = [...prevFrames, newFrame];
      const newIndex = updatedFrames.length - 1;
      setSelectedCropIndex(newIndex);
      return updatedFrames;
    });
    setCropLayerNames((prevNames) => [...prevNames, newName]);
    closeDialog();
  };

  const handleMoveCropFrame = (frameIndex, newX, newY) => {
    const updatedCropFrames = [...cropFrames];
    updatedCropFrames[frameIndex].x = newX;
    updatedCropFrames[frameIndex].y = newY;
    setCropFrames(updatedCropFrames);
  };

  const handleResizeCropFrame = (frameIndex, newWidth, newHeight) => {
    const updatedCropFrames = [...cropFrames];
    updatedCropFrames[frameIndex].width = newWidth;
    updatedCropFrames[frameIndex].height = newHeight;
    setCropFrames(updatedCropFrames);
  };

  const createCroppedVersions = async () => {
    if (!selectedImage || !selectedImageFile) {
      return;
    }
  
    const { name: originalFileNameWithExtension } = selectedImageFile;
    const originalFileName = originalFileNameWithExtension.replace(/\.[^/.]+$/, '');
  
    const imageElement = new Image();
    imageElement.src = selectedImage;
  
    imageElement.onload = async () => {
      const pica = Pica(); // Initialize Pica instance
  
      const croppedImagesData = await Promise.all(cropFrames.map(async (frame, index) => {
        const { x, y, width, height, originalWidth, originalHeight } = frame;
  
        // Create a canvas for the cropped area
        const cropCanvas = document.createElement('canvas');
        const cropCtx = cropCanvas.getContext('2d');
        cropCanvas.width = width;
        cropCanvas.height = height;
  
        // Draw the cropped section from the image
        cropCtx.drawImage(imageElement, x, y, width, height, 0, 0, width, height);
  
        // Create a canvas for upscaling (progressive scaling)
        const upscaledCanvas = document.createElement('canvas');
        const targetWidth = originalWidth * 2;
        const targetHeight = originalHeight * 2;
        upscaledCanvas.width = targetWidth;
        upscaledCanvas.height = targetHeight;
  
        // Perform the progressive scaling using Pica
        await pica.resize(cropCanvas, upscaledCanvas, {
          unsharpAmount: 100,
          unsharpThreshold: 10,
          quality: 3, // Use highest quality for scaling
        });
  
        // Create a final canvas for the scaled-down image
        const finalCanvas = document.createElement('canvas');
        finalCanvas.width = originalWidth;
        finalCanvas.height = originalHeight;
  
        // Scale down to the final resolution using Pica
        await pica.resize(upscaledCanvas, finalCanvas);
  
        // Convert the final canvas to a data URL
        const croppedImage = finalCanvas.toDataURL('image/png', 1); // Set quality
  
        const filename = `${originalFileName}_crop_${originalWidth}x${originalHeight}`;
        return { filename, croppedImage };
      }));
  
      setCroppedImages((prevImages) => [...prevImages, ...croppedImagesData]);
      setShowDownloads(true);
    };
  };

  const downloadCroppedImage = (croppedImageDataUrl, filename, fileExtension) => {
    const a = document.createElement('a');
    a.href = croppedImageDataUrl;
    a.download = `${filename}.${fileExtension}`;
    a.click();
  };

  const downloadAllCroppedImages = () => {
    croppedImages.forEach((croppedImageData, index) => {
      downloadCroppedImage(croppedImageData.croppedImage, croppedImageData.filename, 'png');
    });
  };
  // Delete all cropped images
  const deleteCroppedImages = () => {
    // Clear the croppedImages state
    setCroppedImages([]);
  
    // Optionally clear the selected images as well
    setSelectedImages([]);
  };

  // Select all cropped images
  const selectAllImages = () => {
    const allIndices = croppedImages.map((_, index) => index);
    setSelectedImages(allIndices);
  };

  // Deselect all cropped images
  const deselectAllImages = () => {
    setSelectedImages([]);
  };

  // Delete only the selected cropped images
  const deleteSelectedImages = () => {
    setCroppedImages((prevCroppedImages) =>
      prevCroppedImages.filter((_, index) => !selectedImages.includes(index))
    );
    setSelectedImages([]); // Clear the selected images after deletion
  };

  const zoomIn = () => {
    setZoomFactor((prevZoom) => prevZoom * 1.1);
  };

  const zoomOut = () => {
    setZoomFactor((prevZoom) => prevZoom / 1.1);
  };

  const resetZoom = () => {
    setZoomFactor(1);
  };

  const calculateInitialZoomFactor = useCallback(() => {
    if (selectedImage) {
      const imageElement = new Image();
      imageElement.src = selectedImage;
  
      imageElement.onload = () => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const imageWidth = imageElement.width;
        const imageHeight = imageElement.height;
  
        const widthFactor = windowWidth / imageWidth;
        const heightFactor = windowHeight / imageHeight;
  
        // Calculate initial zoom factor
        let initialZoom = Math.min(widthFactor, heightFactor);
  
        // Cap zoom factor at 1 if the image is smaller than the window
        initialZoom = Math.min(initialZoom, 1);
  
        setZoomFactor(initialZoom);
      };
    }
  }, [selectedImage]);

  useEffect(() => {
    calculateInitialZoomFactor();
  }, [selectedImage, calculateInitialZoomFactor]);

  const handleRemoveCropFrame = (index) => {
    setCropFrames((prevFrames) => {
      const updatedFrames = [...prevFrames];
      updatedFrames.splice(index, 1);
      return updatedFrames;
    });
    setCropLayerNames((prevNames) => {
      const updatedNames = [...prevNames];
      updatedNames.splice(index, 1);
      return updatedNames;
    });
  };

  const handleCycleFocus = (index) => {
    setCropFrames((prevFrames) => {
      const updatedFrames = [...prevFrames];
      const focusedFrame = updatedFrames[index];
      updatedFrames.splice(index, 1);
      updatedFrames.unshift(focusedFrame); // Move the focused frame to the top of the stack
      return updatedFrames;
    });
    // Keep selected frame index updated based on the reordered list
    setSelectedCropIndex(0);
  };

  const renderCropLayers = () => {
    return cropFrames.map((frame, index) => {
      const { originalWidth, originalHeight } = frame;
      const name = `Frame ${originalWidth}x${originalHeight}`; // Keep the name consistent with original dimensions
      return (
        <div
          key={index}
          className={`crop-layer-name${index === selectedCropIndex ? ' selected' : ''}`}
          onClick={() => selectCropFrame(index)}
        >
          {name}
          <span className="remove-layer" onClick={() => handleRemoveCropFrame(index)}>✕</span>
        </div>
      );
    });
  };  

  const renderCropFrames = () => {
    return cropFrames.map((frame, index) => (
      <CropFrame
        key={index}
        {...frame}
        name={cropLayerNames[index]}
        onMove={(newX, newY) => handleMoveCropFrame(index, newX, newY)}
        onResize={(newWidth, newHeight) => handleResizeCropFrame(index, newWidth, newHeight)}
        onSelect={() => selectCropFrame(index)}
        onRemove={() => handleRemoveCropFrame(index)}
        onDoubleClick={() => handleCycleFocus(index)} // Handle double-click for cycling
        selected={index === selectedCropIndex}
        zoomFactor={zoomFactor}
        style={{ zIndex: cropFrames.length - index }} // Set z-index based on index
      />
    ));
  };

  const renderCroppedImages = () => {
    return (
      <div>
        <section>
          <div className='filter download-tools'>
            <span>Filter</span>
            <span className="click" onClick={selectAllImages}>Select All</span>
            <span className="click" onClick={deselectAllImages}>Select None</span>
            <span className="click" onClick={deleteSelectedImages}>Delete Selected</span>
            <span className="click" onClick={deleteCroppedImages}>Delete All</span>
          </div>
          <div className='download download-tools'>
            <span>Download</span>
            <span className="click" onClick={downloadAllCroppedImages}>All</span>
            <span className="click" onClick={downloadSelectedImages}>Selected</span>
          </div>
        </section>
        <div className='grid cropped-images'>
          {croppedImages.map((croppedImageData, index) => (
            <div 
              key={index} 
              data-index={index} 
              onClick={(e) => {
                if (!e.target.classList.contains('click')) { // Only toggle if not clicking the download button
                  toggleImageSelection(index);
                }
              }}
            >
              <img 
                src={croppedImageData.croppedImage} 
                alt={`Cropped ${index + 1}`} 
              />
              <div className="break">
                <p>
                  {croppedImageData.filename.split('_').pop()}<br />
                  <span 
                    className="click" 
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent click event from bubbling up
                      downloadCroppedImage(croppedImageData.croppedImage, croppedImageData.filename, 'png');
                    }}
                  >
                    Download
                  </span>
                  <input
                    type="checkbox"
                    name="select" 
                    id="select"
                    checked={isImageSelected(index)}
                    onChange={() => toggleImageSelection(index)}
                  />
                </p>
              </div>
            </div>
          ))}
        </div>

      </div>
    );
  };

  const zoomedContainerStyle = {
    transform: `scale(${zoomFactor})`,
    transformOrigin: 'center center',
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
  });
  
  return (
    <div className="app">
      {/* Conditionally render crop-layers container if there are crop frames */}
      {cropFrames.length > 0 && (
        <Draggable handle=".toolbar-handle">
          <div className='crop-layers'>
            <div className='crop-layer-label'>Crop Frame Layers <div className="toolbar-handle inline">
                  <div className='handle'>
                    <span>::::::</span>
                  </div>
                </div></div>
            {renderCropLayers()}
          </div>
        </Draggable>
      )}
      <div className='zoom-center'>
        <div className='zoom-container' ref={zoomContainerRef} style={{ width: imageDimensions.width, height: imageDimensions.height }}>
        <div className="zoomed-container" style={{
          ...zoomedContainerStyle, // Spread existing styles
          width: imageDimensions.width,
          height: imageDimensions.height }}>
            <ImageDisplay image={selectedImage} ref={imageRef} />
            {renderCropFrames()}
          </div>
        </div>
      </div>
      <footer>&copy; {(new Date().getFullYear())} Garrett Haas</footer>
        <div className='ui'>
          <Draggable handle=".toolbar-handle">
            <div className="toolbar">
              <div className="toolbar-handle inline">
                <div className='handle'>
                  <span>::::::</span>
                </div>
              </div>
              <div className="zoom-buttons inline">
                <p className='inline'>Zoom: {Math.round(zoomFactor * 100)}%</p>

                <button
                  onMouseEnter={() => setIsZoomInHovered(true)}
                  onMouseLeave={() => setIsZoomInHovered(false)}
                  onClick={zoomIn}
                >
                  +
                </button>

                <button
                  onMouseEnter={() => setIsZoomOutHovered(true)}
                  onMouseLeave={() => setIsZoomOutHovered(false)}
                  onClick={zoomOut}
                >
                  –
                </button>
                <button onClick={resetZoom}>Reset Zoom</button>
              </div>
              <div className="crop-buttons inline">
                <button onClick={openDialog} className={addCropButtonVisible ? '' : 'hide'}>
                  Add Crop Frame
                </button>
                {dialogVisible && (
                  <CropFrameDialog onClose={closeDialog} onConfirm={handleDialogConfirm} />
                )}
                <button onClick={createCroppedVersions}>Make Crops</button>
              </div>
              <div className="view-crops--button inline">
                <button onClick={toggleDownloads}>View Crops</button>
              </div>
              <div className="clear-button inline">
                <button onClick={clearImage}>Clear Image</button>
              </div>
              <div className="toolbar-handle inline">
                <div className='handle'>
                  <span>::::::</span>
                </div>
              </div>
            </div>
          </Draggable>
          {!selectedImage && (
            <div {...getRootProps()} className={`dropzone ${isDragActive ? 'drag-active' : ''}`}>
              <input {...getInputProps()} />
              <p>Drag & drop an image here, or click to select an image</p>
            </div>
          )}
        </div>
      {showDownloads && (
        <div className="downloads">
          <button className='close' onClick={toggleDownloads}><div className='icon'><span className='x'></span><span className='x'></span></div></button>
          {renderCroppedImages()}
        </div>
      )}
    </div>
  );
}

export default Home;
