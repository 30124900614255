import React, { useState, useEffect, useRef } from 'react';

function CropFrameDialog({ onClose, onConfirm }) {
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const widthInputRef = useRef(null);

  useEffect(() => {
    // Focus on the width input when the dialog opens
    widthInputRef.current.focus();
  }, []);

  const handleConfirm = (e) => {
    e.preventDefault(); // Prevent form submission
    // Convert width and height to integers (you can add error handling)
    const parsedWidth = parseInt(width);
    const parsedHeight = parseInt(height);

    // Call the onConfirm callback with the selected dimensions
    onConfirm(parsedWidth, parsedHeight);

    // Close the dialog
    onClose();
  };

  const handleWidthChange = (e) => {
    const newValue = e.target.value;
    if (!isNaN(newValue)) { // Check if the new value is a valid number
      setWidth(newValue + 'px'); // Append 'px' to the end of the value
    }
  };
  
  const handleHeightChange = (e) => {
    const newValue = e.target.value;
    if (!isNaN(newValue)) { // Check if the new value is a valid number
      setHeight(newValue + 'px'); // Append 'px' to the end of the value
    }
  };

  return (
    <div className="crop-frame-dialog inline">
      <form onSubmit={handleConfirm}>
        <label>
          {/* Width (pixels): */}
          <input
            ref={widthInputRef}
            type="number"
            placeholder="Width: px"
            value={width}
            onChange={(e) => setWidth(e.target.value)}
          />
        </label>
        <label>
          {/* Height (pixels): */}
          <input
            type="number"
            placeholder='Height: px'
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
        </label>
        <button type='submit'>Add</button>
      </form>
    </div>
  );
}

export default CropFrameDialog;
