import React from 'react';

// Array of guide steps
const guideSteps = [
  {
    title: "Add an Image: Click or Drag & Drop to Get Started",
    img: "/images/mchowto_1.jpg"
  },
  {
    title: "Use the Toolbar to Zoom, and the Mouse to Pan",
    img: "/images/mchowto_2.jpg"
  },
  {
    title: "Add Crop Frames with the Toolbar",
    img: "/images/mchowto_3.jpg"
  },
  {
    title: "Resize and Position Your First Crop Frame",
    img: "/images/mchowto_4.jpg"
  },
  {
    title: "Add More Crop Frames as Needed",
    img: "/images/mchowto_5.jpg"
  },
  {
    title: "Drag the Toolbar Wherever You Like",
    img: "/images/mchowto_6.jpg"
  },
  {
    title: "Click to Select, or Double-Click to Cycle Through Overlapping FramesClick to select crop frame / resize + position Double click to select crop frames with overlap",
    img: "/images/mchowto_7.jpg"
  },
  {
    title: "Use the Toolbar to Make Crops and Download Your Selections",
    img: "/images/mchowto_8.jpg"
  },
];

const HowTo = () => {
  return (
    <div className="how-to-guide" style={styles.container}>
      <h1 style={styles.header}>Getting Started</h1>
      <div style={styles.list}>
        {guideSteps.map((step, index) => (
          <div className="block" key={index} style={styles.listItem}>
            <img src={step.img} alt={`Step ${index + 1}`} style={styles.image} />
            <h2 style={styles.subHeader}>{step.title}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};

// CSS styles
const styles = {
  container: {
    padding: '20px',
    maxWidth: 'calc(100% - 2rem)',
    margin: '0 auto',
    background: 'rgb(16 16 18 / 96%)',
    color: 'rgba(255,255,255,0.75)',
  },
  header: {
    textAlign: 'center',
    marginBottom: '3rem',
    fontSize: '6rem',
    fontFamily: 'lunchtype22light'
  },
  subHeader: {
    textAlign: 'center',
    zIndex: '9',
    position: 'relative',
    margin: '2rem auto',
    maxWidth: '800px',
    fontSize: '1.75rem',
    fontFamily: 'lunchtype22light'
  },
  list: {
    listStyleType: 'decimal',
    paddingLeft: '20px',
  },
  listItem: {
    margin: '6rem 0',
    padding: '3rem 0 6rem',
    borderBottom: '1px solid rgba(255,255,255,0.1)',
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: '70rem',
    margin: '10px auto',
    border: '1px solid rgba(255,255,255,0.1)',
    borderRadius: '5px',
    overflow: 'hidden',
  },
};

export default HowTo;
