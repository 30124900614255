import React from 'react';
import '../../styles/infosite/footer.css';

const Footer = () => {
  return (
    <footer id="contact" className="footer">
      &copy; {(new Date().getFullYear())} Garrett Haas | <a href=":garrett@garretthaas.com">Contact</a>
    </footer>
  );
};

export default Footer;
