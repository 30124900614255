import React from 'react';

function ImageDisplay({ image, children }) {

  return (
    <div className="image-display">
      {image ? (
        <img
          src={image}
          alt="Selected"
        />
      ) : (
        <div className="empty-message"> </div>
      )}
      {children} {/* Render crop frames */}
    </div>
  );
}

export default ImageDisplay;
