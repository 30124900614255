import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import App from './pages/app';
import HowTo from './pages/howto';

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/app" element={<App />} />
        <Route path="/howto" element={<HowTo />} />
      </Routes>
    </Router>
  );
};