import React, { useEffect, useRef } from 'react';
import '../../styles/infosite/hero.css';

const Hero = () => {
  const videoRef = useRef(null);
  const ctaButtonRef = useRef(null);
  const ghostRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('ended', () => {
        video.currentTime = 0;
        video.play();
      });
    }

    const ctaButton = ctaButtonRef.current;
    const ghost = ghostRef.current;
    const initialButtonPosition = ctaButton.getBoundingClientRect().top; // Capture the initial position
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      const offset = 10; // Add a small buffer to prevent flickering

      // Check if we've scrolled past the initial position of the CTA button
      if (currentScrollPosition >= initialButtonPosition - offset) {
        ctaButton.classList.add('sticky');
        ghost.style.visibility = 'visible'; // Show the ghost element
        ghost.style.position = 'relative';
      } else {
        ctaButton.classList.remove('sticky');
        ghost.style.visibility = 'hidden'; // Hide the ghost element
        ghost.style.position = 'absolute';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section id="hero" className="hero-section">
      <div className="hero-content">
        <p>Image cropping reimagined</p>
        <a ref={ctaButtonRef} className="cta-button" href="/app">Crop an Image</a>
        <div ref={ghostRef} className="ghost"></div> {/* Ghost element */}
      </div>
      <video ref={videoRef} className="video-bg" autoPlay muted loop playsInline>
        <source src="../../../video/mc_hero_02.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </section>
  );
};

export default Hero;
