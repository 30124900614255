import React from 'react';
import '../styles/App.css';
import Header from '../components/infosite/header';
import Hero from '../components/infosite/hero';
// import Features from '../components/infosite/features';
import Footer from '../components/infosite/footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Footer />
    </div>
  );
}

export default App;
