import React, { useState, useEffect } from 'react';

function CropFrame({
  x,
  y,
  width,
  height,
  onMove,
  onResize,
  zIndex,
  selected,
  onSelect,
  onRemove,
  zoomFactor,
  onDoubleClick,
  originalWidth, // Receive original width
  originalHeight, // Receive original height
}) {
  const [dragging, setDragging] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [draggingStarted, setDraggingStarted] = useState(false);
  const [grabbing, setGrabbing] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const delay = 200; // Delay in milliseconds

  // Calculate the original aspect ratio based on original dimensions
  const originalAspectRatio = originalWidth / originalHeight;

  useEffect(() => {
    const handleMouseMove = (e) => {
      const deltaX = (e.clientX - startPosition.x) / zoomFactor;
      const deltaY = (e.clientY - startPosition.y) / zoomFactor;

      if (dragging) {
        if (!draggingStarted) {
          setDraggingStarted(true);
          setGrabbing(true); // Apply grabbing class when dragging starts
        }

        const newX = x + deltaX;
        const newY = y + deltaY;

        onMove(newX, newY);
        setStartPosition({ x: e.clientX, y: e.clientY });
      } else if (resizing) {
        let newWidth = width + deltaX;
        let newHeight = height + deltaY;

        // Maintain aspect ratio during resizing
        if (deltaX > deltaY) {
          newHeight = newWidth / originalAspectRatio;
        } else {
          newWidth = newHeight * originalAspectRatio;
        }

        if (newWidth > 0 && newHeight > 0) {
          onResize(newWidth, newHeight);
        }

        setStartPosition({ x: e.clientX, y: e.clientY });
      }
    };

    const handleMouseUp = () => {
      clearTimeout(timeoutId);
      setDragging(false);
      setResizing(false);
      setDraggingStarted(false);
      setGrabbing(false); // Remove grabbing class when mouse is released
      setHovering(false); // Ensure hovering state is reset on mouse up
    };

    if (dragging || resizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, resizing, startPosition, x, y, width, height, onMove, onResize, zoomFactor, draggingStarted, timeoutId, originalAspectRatio]);

  const handleMouseDown = (e) => {
    e.preventDefault();

    // Set a timeout to apply the grabbing class after a delay
    const id = setTimeout(() => {
      if (!resizing) {
        setGrabbing(true);
      }
    }, delay);
    setTimeoutId(id);

    if (e.target.classList.contains('resize-handle')) {
      setResizing(true);
      setStartPosition({ x: e.clientX, y: e.clientY });
    } else {
      setDragging(true);
      setStartPosition({ x: e.clientX, y: e.clientY });
    }

    onSelect(zIndex); // Ensure selection happens only for the specific frame
  };

  const handleMouseEnter = () => {
    if (!resizing) {
      setHovering(true);
    }
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  const handleDoubleClick = (e) => {
    e.preventDefault();
    onDoubleClick(zIndex); // Notify parent to handle frame cycling
  };

  const handleRemove = (e) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <div
      className={`crop-frame${selected ? ' selected' : ''}${grabbing ? ' grabbing' : ''}${resizing ? ' resizing' : ''}${hovering && !resizing ? ' hovering' : ''}`}
      style={{
        left: x + 'px',
        top: y + 'px',
        width: width + 'px',
        height: height + 'px',
        zIndex,
        borderWidth: `${1 / zoomFactor}px`,
      }}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDoubleClick={handleDoubleClick} // Add double-click handler
    >
      <div
        className="frame-label"
        style={{
          fontSize: `${(.75 / zoomFactor)}rem`,
        }}
        >
        {originalWidth} x {originalHeight} px ({originalAspectRatio.toFixed(2)}) {/* Use original aspect ratio */}
      </div>
      <div className="resize-handle br"></div>
      <button className="remove-button" onClick={handleRemove}></button>
    </div>
  );
}

export default CropFrame;
