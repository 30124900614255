import React from 'react';
import './styles/App.css';
import { AppRoutes } from './Routes'; // Correct import

function App() {
  return (
    <AppRoutes />
  );
}

export default App;